@keyframes swayCloth {
  0% {
    transform: rotate(-10deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/* Cloth element initial appearance */
.cloth {
  opacity: 0;
  transform: translateY(-100%);
  animation: fadeInAndSway 3s forwards;
  /* Adjust timing as needed */
}

/* Combined Fade-in and Sway Animation */
@keyframes fadeInAndSway {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  20% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }

  /* Continue with sway movement */
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
}

/* Initiating cloth animation on scroll */
.animate-on-scroll {
  animation: swayCloth 2s ease-in-out forwards, fadeInAndSway 3s forwards;
}

@keyframes carouselMove {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.logo-animate-b {
  flex: 0 0 auto;
  /* Do not grow or shrink */
  animation: carouselMove 10s linear infinite;
  opacity: 0;
  /* Start faded out */
}


.howItWorks {
  margin-top: 2.5rem;
  text-align: center;
}

.howItWorks img {
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); */
}

.howItWorks>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoCon {
  width: 100%;
  position: relative;
  z-index: 2;
}

.videoCon video {
  width: 68%;
  border-radius: 20px;
  box-shadow: 0 0 12px -4px #9a9a9a;
}

.demoImage {
  width: 90vw;
  z-index: 2;
  border-radius: 15px;
}

.trustedCompanies {
  padding: 3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trustedCompanies>h5 {
  color: #3F3F3F;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 16px;
}

.companiesList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4rem;
}

.companiesList img {
  width: 7rem;
}

.customWidth9{
  width: 9rem !important;
}

@media (max-width: 320px) {}

@media (max-width: 481px) {}

@media (max-width: 769px) {}

@media (max-width: 480px) {
  .videoCon video {
    border-radius: 8px;
  }

  .howItWorks {
    margin-top: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .companiesList {
    column-gap: 2rem;
  }

  .companiesList img {
    width: 6rem;
  }
  
  .customWidth9{
    width: 8rem !important;
  }
}

@media (max-width:1000px) {
  .trustedCompanies {
    background: transparent;
  }
}

@media (max-width: 800px) {
  .companiesList {
    column-gap: 1rem;
    row-gap: 0.5rem;
  }
  .trustedCompanies>h5{
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .companiesList img {
    width: 4rem;
  }
  .customWidth9{
    width: 5.5rem !important;
  }
}