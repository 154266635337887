.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.7rem 2rem 2rem 2rem;
    border-radius: 8px;
    width: 500px;
    max-height: 85vh;
    /* overflow-y: auto; */
    text-align: left;
    border: none;
    position: relative;
}

.close {
    position: absolute;
    right: 23px;
    top: 16px;
    cursor: pointer;
}

.close img {
    height: 1rem;
    width: 1rem;
}

.close svg {
    height: 1.5rem;
}

.icon {
    /* height: 2rem; */
    width: 1.3rem;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.summaryBg h2 {
    margin: 20px 0;
    font-size: 17px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}

.summaryBg h3 {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
}

.para {
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.scroll {
    height: 32rem;
    overflow-y: auto;
    padding-right: 5px;
    padding-top: 2px;
}

.scroll::-webkit-scrollbar {
    width: 10px;
}

.scroll::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d49 90%);
    border-radius: 7px;
}