.section {
  /* border: 1px solid black; */
  background: #013579;
  padding: 5rem 3rem;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  /* border: 1px solid black; */
  /* min-height: 50vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.subheading {
  font-size: 32px;
  font-family: "Manrope", sans-serif;
  color: white;
  text-align: start;
  margin: 0;
  line-height: 2.3rem;
}

.text {
  font-family: "Manrope", sans-serif;
  text-align: start;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
}

.blue {
  color: #0262df;
}

.boxTitle {
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

.boxesList {
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* gap: 2rem; */
  margin-top: 5rem;
  gap: 2rem;
}

.box {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 2rem 1.2rem;
  max-width: 15rem;
  border-radius: 15px;
}

.miniBox {
  height: 4rem;
  width: 4rem;
  background: #0262df;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.miniBox span {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.miniBox img {
  height: 2.5rem;
}

.boxText {
  font-family: "Manrope", sans-serif;
  text-align: start;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #ffffffe6;
  line-height: 23px;
}

.colored {
  color: #0262df;
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  margin: 0;
  margin-top: 0.5rem;
  cursor: pointer;
}

.colored:hover {
  text-decoration: underline;
}

.featureCard {
  padding: 1.8rem;
  background: transparent;
  position: relative;
  text-align: left;
  margin: 0.3rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding: 2rem 1.2rem;
  max-width: 17rem;
  border-radius: 15px;
  align-self: stretch;
}

.cardBg {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: 0 !important;
  padding: 0 !important;
  transition-property: all;
  transition-duration: 0.3s;
  z-index: 0;
}

.featureCard:hover .cardBg {
  transform: scale(1.05);
}

.iconImage {
  width: 70px;
  height: 60px;
}

@media (max-width: 1550px) {
  .boxTitle {
    font-size: 18px;
  }

  .boxesList {
    margin-top: 3rem;
  }

  .boxText {
    font-size: 14px;
  }

  .miniBox {
    height: 3rem;
    width: 3rem;
  }

  .miniBox img {
    height: 1.4rem;
  }

  .boxText br {
    display: none;
  }
}

@media (max-width: 1360px) {
  .featureCard {
    padding: 2rem 1rem;
  }

  .subheading br {
    display: none;
  }

  .text br {
    display: none;
  }

  .boxText br {
    display: none;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1100px) {
}

@media (max-width: 1000px) {
  .subheading {
    font-size: 28px;
  }

  .text {
    font-size: 13px;
  }

  .AccordionHeader > h5 {
    font-size: 14px;
  }

  .accordionText {
    font-size: 12px;
  }

  .card > div {
    padding: 1rem;
  }

  .subheading,
  .text {
    text-align: center;
  }

  .howItWorks {
    padding: 1rem 3rem;
  }

  .boxesList {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 2rem;
  }
}

@media (max-width: 900px) {
}

@media (max-width: 800px) {
  .boxLeftLine,
  .boxRightLine {
    display: none;
  }

  .boxText {
    max-width: 20rem;
  }
}

@media (max-width: 600px) {
}

@media (max-width: 480px) {
  .subheading {
    font-size: 22px;
  }

  .text {
    font-size: 12px;
  }
}
