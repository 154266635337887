*{
  box-sizing: border-box;
}

.faqs .css-1nvcaqg-MuiFormControl-root-MuiTextField-root {
  padding: 0 !important;
}

.faqs .css-j204z7-MuiFormControlLabel-root {
  margin-left: 0 !important;
}

.faqs .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 4px;
}

.faqs .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

/* .faqs .css-15v22id-MuiAccordionDetails-root {
  padding: 0 0 8px 0 !important;
} */

.faqs .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 32px !important;
}

/* .css-15v22id-MuiAccordionDetails-root {
  padding: 8px 12px 16px;
} */


#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 100 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: #00c8b1;
  border-left-color: #00c8b1;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.css-1iji0d4{
  background: none !important;
}

::selection{
  background: #0262DF;
  color: white;
}

img, button{
  user-select: none;
}

@keyframes ldio-x2uulkbinbj {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}

.loadingio-spinner-eclipse-nq4q5u6dq7r {
  width: 25px;  /* Set width to 25px */
  height: 25px; /* Set height to 25px */
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-x2uulkbinbj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-x2uulkbinbj div { 
  box-sizing: content-box;
}

.ldio-x2uulkbinbj div {
  position: absolute;
  animation: ldio-x2uulkbinbj 1s linear infinite;
  width: 20px;   /* Reduce width */
  height: 20px;  /* Reduce height */
  top: 2.5px;    /* Adjust position */
  left: 2.5px;   /* Adjust position */
  border-radius: 50%;
  box-shadow: 0 1px 0 0 #ffffff; /* Adjust shadow size */
  transform-origin: 10px 10px;   /* Adjust transform origin */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}