.hero{
    /* min-height: 58vh; */
    margin-bottom: 5rem;
}
.hero h1{
    font-size: 25px;
    font-family: 'Manrope', sans-serif;
    color: black;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 4.5rem;
}
.hero h5{
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    color: black;
    margin: 0;
    font-size: 15px;
}
.mini_title{
    font-weight: 700 !important;
}
.major_title{
    font-size: 17px !important;
    font-weight: 700 !important;
}
.form_con{
    width: 60%;
    margin: auto;
}
.abc_ol{
    list-style-type: circle !important;
}
ol{
    margin: 0;
    margin-top: 2px;
}
a{
    color: #0262DF;
    text-decoration: none;
}

@media (min-width: 320px) and (max-width: 480px) {
    .form_con{
        width: 80%;
    }
    .hero h5{
        font-size: 13.5px;
    }
    .major_title{
        font-size: 15px !important;
    }
}
@media (min-width: 481px) and (max-width: 768px) {
    .form_con{
        width: 75%;
    }
    .hero h5{
        font-size: 14px;
    }
    .major_title{
        font-size: 16px !important;
    }
}
@media (min-width: 769px) and (max-width: 992px) {
    .form_con{
        width: 70%;
    }
}