.navContainer {
    padding: 0 5rem;
    border: 1px solid transparent;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    border: 1px solid #EAEAEA;
}

.navContainer div:nth-child(1) {
    display: flex;
    justify-content: start;
    align-items: center;
}

.logoImage {
    /* width: 200px; */
    height: 30px;
    margin-bottom: 13px;
    margin-right: 20px;
}

.navContainer nav:nth-child(2) {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
}

.navContainer div:nth-child(3) {
    display: flex;
    justify-content: end;
    align-items: center;
}

.navElemWidth{
    width: 17rem;
}

.logoLink {
    cursor: pointer;
    text-decoration: none;
}

.navMenu a {
    color: black;
    font-family: 'Manrope', sans-serif;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
}

.navLink:last-child {
    padding-right: 0;
}

.dashboardLink {
    cursor: pointer;
    text-decoration: none;
}

.dashboardIcon {
    color: #0262DF;
    font-size: 35px;
}

.authButtons {
    cursor: pointer;
    text-decoration: none;
}

.signInButton {
    background: none;
    border: 1px solid #DDDDDD;
    height: 38px;
    border-radius: 8px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    font-size: 13px;
    padding: 0.3rem 2rem;
    text-decoration: none;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.2s ease;
}

/* .signInButton:hover{
    transform: scale(1.04);
    font-weight: 700;
} */

.whiteSignin{
    border: 1px solid #DDDDDD;
    color: black;
}

.blueSignin{
    border: 1px solid #DDDDDD;
    color: black;
    /* border: 1px solid #0262DF;
    color: #0262DF; */
}


.tryFreeButton {
  outline: none;
  border: none;
  background: none;
  background: linear-gradient(90deg, #0262df 0%, #013579 100%);
  height: 38px;
  border-radius: 8px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  font-size: 13px;
  padding: 0.3rem 1.5rem;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.2s ease;
}

/* .tryFreeButton:hover{
  transform: scale(1.03);
} */


.tryFreeButton h5 {
    color: white;
    margin: 0;
    transition: all 0.3s ease;
}
/* 
.tryFreeButton:hover h5{
  font-weight: 700;
} */

.whiteSignup{
    color: #0262DF;
    background: white;
}

.blueSignup{
    color: white;
    /* background: #0262DF; */
    background: linear-gradient(90deg, #0262df 0%, #013579 100%);
}

@media (max-width: 1560px) {
    .logoImage{
        margin-right: 15px;
    }
}
@media (max-width: 1200px) {
    .navElemWidth{
        width: 15rem;
    }
    .navContainer{
        padding: 0 3rem;
    }
}
@media (max-width: 1100px) {
    .navContainer nav:nth-child(2) {
        gap: 1rem;
    }
}


@media (max-width: 1100px) {
    .tryFreeButton {
        padding: 0.3rem 1rem;
    }

    .signInButton {
        padding: 0.3rem 1.5rem;
    }
}
