/* Replace "selector" with the appropriate selector for your HTML element */
.promptBox {
  /* Add initial boxShadow style (optional) */
  /* transition: boxShadow 0.3s ease; Add a transition for smooth animation */
}

.promptBox:hover {
  /* box-shadow: 0px 3px 39px -4px rgba(25, 85, 230, 0.15); */
}

/* CSS file */
.deleteIcon {
  color: gray;
  font-size: 22px;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: color 0.3s ease;
  /* Smooth transition for color change */
}

.deleteIcon:hover {
  color: #0262df;
  /* Red color on hover */
}

.container {
  padding: 2rem 1.5rem;
  overflow: auto;
}

.newChatBtn {
  background-color: #0262DF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  height: 2.85rem;
  padding: 0rem 1rem;
  transition-property: all;
  transition-duration: 0.3s;
  cursor: pointer;
  margin-bottom: 2rem;
  border: none;
  width: 100%;
  color: white;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.newChatBtn span {
  padding-right: 0.7rem;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.con1 {
  /* border: 1px solid black; */
  min-height: 10rem;
}

.con1 h5 {
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.listCon {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.85rem;
  padding-right: 1rem;
  transition-property: all;
  transition-duration: 0.3s;
  cursor: pointer;
  margin-bottom: 3px;
}

.listCon:hover {
  background: rgba(2, 98, 223, 0.15);

}

.listCon:hover h5 {
  color: #0262DF;
}

.listCon h5 {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #939393;
  margin: 0;
  height: 100%;
  width: 100%;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.listCon img {
  height: 17px;
}

.noRecord h5 {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #939393cf;
  margin: 1rem 0;
  text-align: center;
}

@media (max-width: 1130px) {
  .container {
    padding: 2rem 0.5rem;
  }

  .con1 h5 {
    font-size: 13px;
  }

  .listCon h5 {
    font-size: 13px;
  }

  .container{
    padding: 2rem 0.7rem;
  }
}