/* Navbar */
.navContainer {
    border: 1px solid transparent;
    height: 4.5rem;
    display: grid;
    grid-template-columns: 27% 46% 27%;
    position: relative;
    background-color: white;
}

.navContainer div:nth-child(1) {
    display: flex;
    justify-content: end;
    align-items: center;
}

.logoImage {
    /* width: 200px; */
    height: 60px;
    margin-bottom: 5px;
}

.navContainer nav:nth-child(2) {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
}

.navContainer div:nth-child(3) {
    display: flex;
    justify-content: start;
    align-items: center;
}

.logoLink {
    cursor: pointer;
    text-decoration: none;
}

.navMenu a {
    color: black;
    font-family: 'manrope', sans-serif;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.navLink:last-child {
    padding-right: 0;
}

.goBackTxt {
    color: #0262DF;
    cursor: pointer;
}

.dashboardLink {
    cursor: pointer;
    text-decoration: none;
}

.dashboardIcon {
    color: #0262DF;
    font-size: 35px;
}

.authButtons {
    cursor: pointer;
    text-decoration: none;
}


@media (max-width: 1100px) {
    .navContainer nav:nth-child(2) {
        gap: 1rem;
    }
}


@media (max-width: 1100px) {
    .tryFreeButton {
        padding: 0.3rem 1rem;
    }

    .signInButton {
        padding: 0.3rem 1.5rem;
    }
}

@media (max-width: 900px) {
    .logoLink {
        display: none;
    }
}

@media (max-width: 700px) {
    .navContainer {
        grid-template-columns: 0% 0% 100%;
    }

    .navContainer div:nth-child(3) {
        justify-content: center;
    }
}


.section {
    min-height: 83vh;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundImage {
    background-color: #FBFBFD;
}

.boxStyles {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 21rem;
    min-width: 21rem;
    padding: 2rem 3rem;
    background: white;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.miniLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.miniLogo img {
    height: 2rem;

}

.heading {
    font-family: 'Manrope', sans-serif;
    text-align: center;
    margin-bottom: 10px;
}

.subtitle {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.preDomain {
    padding: 0px 12px;
    background: rgba(2, 98, 223, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.preDomain h5 {
    margin: 0;
    font-size: 13px;
    color: #565656;
}

.passwordCon {
    padding: 5px 15px;
    padding-left: 1rem;
    border-radius: 8px;
    height: 38px;
    border: 1px solid rgba(230, 232, 236, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
}

.passwordCon input {
    height: 92%;
    width: 80%;
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
    border: none;
    background: none;
    outline: none;
}

.passwordCon input:-webkit-autofill {
    height: 92%;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.passwordCon svg {
    height: 15px;
    fill: #242436;
    cursor: pointer;
}

.textWithLines {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.textWithLines hr {
    border-color: #ebebeb3d;
    margin: 0;
    width: 100%;
}

.textWithLines span {
    font-size: 13px;
    font-family: 'Manrope', sans-serif;
}

.inputField {
    padding-left: 1rem;
    font-size: 16px;
    font-family: Poppins, sans-serif;
    border-radius: 8px;
    height: 44px;
    color: #242436;
    border: 1px solid rgba(230, 232, 236, 1);
    outline: none;
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
    margin-bottom: 1rem;
}

.inputField:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
}

.inputError {
    border: 2px solid #f61d1d;
}

.error {
    margin: 0;
    font-size: 13px;
    color: #f61d1d;
}

.success {
    margin: 0;
    font-size: 13px;
    color: green;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    border-radius: 8px;
    height: 44px;
    width: 100%;
    color: rgba(78, 86, 109, 1);
    border: 1px solid rgba(230, 232, 236, 1);
    cursor: pointer;
    margin-top: 1rem;
    gap: 10px;
}

.googleText {
    font-family: 'Manrope', sans-serif;
}

.googleIcon {
    height: 1.2rem;
}

.signInButton {
    background: #0362e0;
    color: white;
    border: 1px solid transparent;
    font-family: "Manrope", sans-serif;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.flexAlign {
    display: flex;
    align-items: center;
}

.check {}

.forgotPasswordButton {
    /* color: #0362e0; */
    color: black;
    cursor: pointer;
    border-color: transparent;
    background: transparent;
    font-size: 13px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
    text-align: start;
    margin: 0;
    padding: 0;
    max-width: 20rem;
}

.forgotPasswordButton:hover{
    text-decoration: underline;
}

.signupLink {
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    font-family: "Manrope", sans-serif;
    margin: 0;
    /* margin-bottom: 11px; */
    line-height: 1rem;
    max-width: 20rem;
}

.signupLink2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 13px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 11px;
    line-height: 1rem;
    /* cursor: pointer; */
}

/* .signupLink2:hover {
    text-decoration: underline;
} */

.linkButton {
    color: #0362e0;
    cursor: pointer;
    border-color: transparent;
    background: transparent;
    font-size: 12px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1rem;
}

.flex3{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 4px;
}

@media (max-width: 480px) {

    .section {
        align-items: start;
        margin-top: 2rem;
    }

    .boxStyles {
        min-width: 15rem;
        padding: 2rem 2rem;
    }

    .miniLogo {
        height: 2rem;
    }

    .heading {
        margin-bottom: 5px;
        margin-top: 8px;
        font-size: 19px;
    }

    .subtitle {
        font-size: 13px;
    }

    .inputField {
        font-size: 14px;
        height: 35px;
    }

    .passwordCon {
        height: 35px;
    }

    .passwordCon input {
        font-size: 14px;
    }

    .signInButton {
        font-size: 13px;
        height: 35px;
    }
}