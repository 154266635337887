.mainGrid{
    display: grid;
    grid-template-columns: 75% 25%;
    position: relative;
    height: 100%;
}

.gridLeft{
    display: flex; 
}

.gridRight{
    border-left: 1px solid lightgray;
    overflow-y: auto;
    max-height: 100vh;
}

@media (min-width: 1400px) {
    .gridLeft{
        justify-content: center;
    }
}
@media (max-width: 1200px) {
    .mainGrid{
        grid-template-columns: 70% 30%;
    }
}
@media (max-width: 900px) {
    .mainGrid{
        grid-template-columns: 100%;
    }
    .gridRight{
        display: none;
    }
}


