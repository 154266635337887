.howItWorks {
  /* border: 1px solid black; */
  background: #fbfbfb;
  padding: 5rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  /* border: 1px solid black; */
  min-height: 57vh;
  width: 75%;
  display: grid;
  grid-template-columns: 40% 60%;
}

.card > div {
  /* border: 1px solid blueviolet; */
  height: 100%;
}

.cardLeft {
  /* border: 1px solid red; */
  width: 25rem;
}

.subheading {
  font-size: 28px;
  font-family: "Manrope", sans-serif;
  color: black;
  text-align: start;
  margin: 0;
  line-height: 2.5rem;
}

.text {
  font-family: "Manrope", sans-serif;
  text-align: start;
  line-height: 1.6rem;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0 2rem 0;
}

.blue {
  color: #0262df;
}

.AccordionHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.miniBox {
  height: 2rem;
  width: 2rem;
  background: linear-gradient(91.4deg, #0262df 1.19%, #013579 96.54%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.miniBox img {
  height: 1.1rem;
}

.AccordionHeader > h5 {
  color: black;
  font-size: 15px;
  font-weight: 700;
}

.accordionText {
  font-family: "Manrope", sans-serif;
  text-align: start;
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 600;
}

.accordinBody {
  padding-left: 3.5rem !important;
  padding-right: 2rem !important;
  padding-top: 0 !important;
}

.colored {
  color: #0262df;
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  margin: 0;
  margin-top: 1rem;
  cursor: pointer;
}

.colored:hover {
  text-decoration: underline;
}

.cardRight {
  /* border: 1px solid black; */
  border-radius: 15px;
  display: flex;
  /* align-items: center; */
  padding-top: 25px;
  justify-content: end;
  overflow: hidden;
}

.bgBlue {
  background: linear-gradient(136.22deg, #0262df 0%, #013579 96.46%);
  height: 100%;
  width: 100%;
  display: flex;
  /* padding-left: 1rem; */
  border-radius: 15px;
}

.cardRight span {
  display: flex !important;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 1rem 0 1rem 1rem; */
  box-sizing: border-box;
}

.cardRight img {
  max-width: 96%;
  margin-right: -7px;
  transition: transform 0.3s ease, background-color 0.2s ease;
}

.cardRight img:hover {
  transform: scale(1.03);
}

@media (max-width: 1800px) {
  .card {
    min-height: 63vh;
  }
}

@media (max-width: 1600px) {
  .card {
    min-height: 71vh;
    width: 85%;
  }
  .bgBlue {
    height: 75vh;
  }
}

@media (max-width: 1300px) {
  .cardLeft {
    width: 22rem;
  }
}

@media (max-width: 1200px) {
  .card {
    min-height: 75vh;
    min-width: 100%;
  }
  .bgBlue {
    width: 90%;
  }
}

@media (max-width: 1100px) {
}

@media (max-width: 1000px) {
  .card {
    grid-template-columns: 40% 60%;
    justify-content: center;
  }

  .subheading {
    font-size: 28px;
  }

  .text {
    font-size: 13px;
  }

  .AccordionHeader > h5 {
    font-size: 14px;
  }

  .accordionText {
    font-size: 12px;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .card > div {
    padding: 1rem;
  }

  .cardLeft {
    width: 30rem;
  }

  .cardRight {
    width: 100%;
    justify-content: center;
  }

  .bgBlue {
    padding: 1.5rem;
    height: auto;
  }

  .cardRight img {
    border-radius: 1rem;
    max-width: 100%;
    height: 100%;
    margin-right: 0px;
  }

  .subheading,
  .text {
    text-align: center;
  }

  .howItWorks {
    padding: 1rem 3rem;
  }

  .cardRight {
    width: 85%;
  }

  .bgBlue {
    border-radius: 28px;
  }
}

@media (max-width: 900px) {
}

@media (max-width: 800px) {
  .bgBlue {
    border-radius: 20px;
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  .cardLeft {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .subheading {
    font-size: 22px;
  }

  .text {
    font-size: 12px;
  }

  .cardRight {
    width: 95%;
  }

  .bgBlue {
    border-radius: 20px;
    padding: 10px;
  }
}
