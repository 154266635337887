@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

.banner {
    background-color: white;
    padding: 17px 5%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 7%);
}

.banner>div {
    /* border: 1px solid black; */

}

.banner h5,
.hero3 h5,
.hero2 h5,
.hero1 h5,
h3 {
    margin: 0;
}

.bannerMid {
    border: 1px solid black;
    border-radius: 3px;
    height: 2rem;
    padding: 4px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
}

.bannerMid h5 {
    font-size: 13px;
}

.bmLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
}

.bmRight {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 7px;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
}

.flexAlign20 {
    display: flex;
    align-items: center;
    gap: 20px;
}

.flexAlign10 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pad3 {
    padding: 10px;
}

.bannerRight {
    display: flex;
    align-items: center;
    gap: 10px;
}

.hero1 {
    min-height: 4rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 6%);
    border-radius: 15px;
    margin: 20px 5%;
    padding: 20px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.miniLine {
    height: 4rem;
    background: rgb(0 0 0 / 6%);
    width: 2px;
}

.hero1Img {
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero1Img img {
    height: 20px;
}

.heroBox {
    display: flex;
    align-items: center;
    gap: 12px;
}

.hero2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 5%;
    margin-top: 3rem;
}

.hero2 h5 {
    font-size: 13px;
    font-weight: 600;
}

.searcCon {
    border: 1px solid #BEBEBE;
    border-radius: 32px;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: 4px 12px;
    margin-right: 2.5%;
}

.searcCon h5 {
    margin: 0;
    line-height: 1rem;
    text-align: center;
}

.searcCon input {
    height: 100%;
    border: none;
    background: none;
    outline: none;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    width: 9rem;
}

.searcCon input::placeholder {
    color: rgb(153, 153, 153);
}

.searcCon img {
    height: 0.9rem;
}

.searcCon span {
    color: #0262DF;
    font-size: 17px;
}

.topBox {
    background: #FBFBFB;
    border: 1px solid #bebebeb0;
    border-radius: 6px;
    padding: 4px 8px;
}

.mr10 {
    margin-right: 10px;
}

.hero3 {
    padding: 0 5%;
    border-radius: 7px;
    margin-bottom: 1rem;
}

.tableCon {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 7px 7px;
    border-radius: 7px;
}

.tableHeader {
    border-radius: 7px 7px 0 0;
    display: grid;
    grid-template-columns: 10% 10% 20% 15% 14% 17% 14%;
    padding: 9px 20px;
}

.tableHeader h5 {
    font-size: 13px;
    color: white;
}

.rowHeader {
    display: grid;
    padding: 9px 20px;
    grid-template-columns: 10% 10% 20% 15% 14% 21% 6% 4%;
}

.rowHeader h5 {
    font-size: 13px;
    font-weight: 700;
}

.rowExpanded {
    padding: 9px 20px;
    background: #F8F8F8;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-height: 25rem;
    overflow-y: auto;
}

.expandedMiniCon {
    background: white;
    border-radius: 7px;
}

.expandedMiniConPadd {
    padding: 15px 20px;

}

.miniTitle {
    font-size: 15px;
    font-weight: 700;
}

.line {
    border: 1px solid rgb(0 0 0 / 6%);
}

.para {
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px !important;
}

.para2 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 15px !important;
    margin-top: 5px !important;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.miniColoredText {
    font-size: 13px;
    font-weight: 600;
}

.m10 {
    margin: 10px 0;
}

.con3 {
    margin: 13px 0;
}

.con3 h5 {
    font-size: 14px;
    font-weight: 700;
}

.con3Footer h5 {
    font-size: 14px;
    font-weight: 700;
    color: white;
}

.con3Footer {
    padding: 15px 20px;
    border-radius: 0 0 7px 7px;
    margin: 0 !important;
}

.icon3 {
    height: 15px;
}

.pagFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px;
}

.pagFooter h5 {
    font-size: 13px;
    font-weight: 700;
}

.pagFooter li button {
    font-family: 'manrope', sans-serif;
    font-weight: 700;
    font-size: 13px;
    min-width: 20px;
    height: 23px;
    padding: 0 3px;
    margin: 0 1px;
}

/* .pagination {
    background: #FBFBFD;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
} */

.arrow{
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.3s;
}

.fw600{
    font-weight: 600;
}

.container {
    padding: 15px 4%;
    padding-bottom: 3rem;
    margin-left: 2%;
    /* margin-top: -1.8rem; */
    /* border: 2px solid black; */
}


.accordion {
    background-color: transparent !important;
    margin-bottom: 0px;
    box-shadow: none !important;
}

.accordionHeader {
    /* border: 1px solid black; */
    padding: 0 !important;
}

.accordionHeader > div{
    margin: 0 !important;
}

.accordionBody {
    /* border: 1px solid red; */
    padding: 0px 16px !important;
    padding-bottom: 10px !important;
}

.accordionHeader{
    min-height: 10px !important;
}

