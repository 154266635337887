.menu-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.bgMenu {
  background: rgb(0 0 0 / 46%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}

.menu-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f9f9f9;
  position: absolute;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 5px 5px 0 0;
  z-index: 5;
}

.menu-wrapper img {
  position: absolute;
  width: 10rem;
  left: 10px;
  top: 10px;
}

.mobile {
  display: none;
}

.menu-icon svg {
  height: 20px;
  width: 20px;
  fill: #0162df;
}

.menu-line {
  width: 100%;
  height: 2px;
  background-color: #333;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-in-out;
}

.menu-line:first-child {
  top: 0;
}

.menu-line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.menu-line:last-child {
  bottom: 0;
}

.menu-navigation {
  position: fixed;
  top: 0;
  right: -200px;
  height: 100%;
  width: 200px;
  background-color: rgb(26 83 156);
  /* Change to a non-transparent color */
  transition: all 0.2s ease-in-out;
  z-index: 9999;
}

.crossCon {
  display: flex;
  justify-content: end;
  align-items: center;
}

.crossIcon {
  fill: white;
  height: 2rem;
  margin: 1rem;
}

.menu-navigation.open {
  right: 0;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  margin-top: 1rem;
  margin-right: 1rem;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.close-btn:hover {
  color: #e7e7e7;
}

.menuLine {
  width: 76%;
  color: #777777;
  border: none;
  border-bottom: 1px solid #ffffff40;
}

.navItemsCon {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 10rem;
}

.navItemsCon > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu-navigation li {
  margin-bottom: 1rem;
}

.menu-navigation a {
  color: white;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 400;
  /* text-transform: uppercase; */
  width: 100%;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem;
  padding-left: 1.5rem;
  transition-property: all;
  transition-duration: 0.3s;
}

.menu-navigation a:hover {
  background: #e7e7e7;
}

@media (max-width: 480px) {
  .mobile {
    display: block;
  }
  
  .menu-wrapper img {
    width: 6rem;
    top: 20px;
  }
}
