.sectionA {
    min-height: 80vh;
    padding: 2rem;
    background: linear-gradient(135deg, #eff6ff 0%, #d1e8ff 100%); /* Gradient background */
  }
  
  .gridContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  
  .textContainer {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    animation: slideInFromLeft 1s ease forwards; /* Animation for text */
  }
  
  .mainHeading {
    font-weight: 700;
    font-size: 25px;
    font-family: 'Helvetica, sans-serif';
    animation: fadeIn 1s ease forwards; /* Animation for heading */
  }
  
  .highlightText {
    color: rgb(6 100 222);
  }
  
  .subText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .icon {
    color: rgb(6 100 222);
    margin-right: 0.4rem;
  }
  
  .primaryButton, .secondaryButton {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    font-family: 'Poppins, sans-serif';
    font-weight: 550;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease; /* Transition for hover effect */
  }
  
  .primaryButton {
    background: #0362e0;
    color: white;
    margin-right: 1rem;
  }
  
  .secondaryButton {
    background: white;
    color: #0362e0;
    border: 2px solid #0362e0;
  }
  
  .primaryButton:hover, .secondaryButton:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }
  
  .freeTrialText {
    margin-top: 1rem;
  }
  
  .imageContainer {
    position: relative;
    overflow: hidden;
  }
  
  .responsiveImage {
    max-width: 100%;
    transition: transform 0.5s ease-out;
  }
  
  .imageContainer:hover .responsiveImage {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInFromLeft {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }
  