:root {
    --black: black;
    --blue: #0262DF;
}

.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    width: fit-content;
    text-align: left;
    border: none;
    position: relative;
}

.close {
    position: absolute;
    right: 9px;
    top: 4px;
    cursor: pointer;
}

.close svg {
    height: 0.5rem;
}

.cardCon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 20px 25px;
    border-radius: 8px;
}

.cardCon h5 {
    margin: 0;
}

.cardCon button {
    border: none;
    background-color: var(--blue);
    border-radius: 7px;
    padding: 7px 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.cardCon button h5 {
    color: white;
    font-size: 15px;
}

.cardCon a {
    text-decoration: none;
}

.imgCloud {
    height: 2.5rem;
    margin-bottom: 10px;
}

.chooseFile {
    height: 1.2rem;
}

.title {
    font-weight: 600;
    font-size: 18px;
}

.text1 {
    font-size: 16px;
    margin: 15px 0;
}

.text2 {
    font-size: 15px;
}

.text3 {
    font-size: 14px;
    color: var(--blue);
}