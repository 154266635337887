@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

.banner {
    background-color: white;
    padding: 17px 5%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 7%);
}

.fw600 {
    font-weight: 600;
}

.banner h5 {
    margin: 0;
}

.hero {
    margin: 20px 5%;
}

.accordion {
    background-color: transparent !important;
    margin-bottom: 10px;
    box-shadow: none !important;
}

.accordionHeader {
    /* border: 1px solid black; */
    padding: 0 !important;
}

.accordionBody {
    /* border: 1px solid red; */
    padding: 0px 16px !important;
    padding-bottom: 10px !important;
}

.padding {
    margin: 20px 5%;
}

.title {
    font-size: 17px;
    font-weight: 600;
}


.colorSchemeTextInput {
    font-family: 'Manrope', sans-serif;
    color: #666563;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    border: none;
}

.textAlign {
    display: flex;
    align-items: center;
    gap: 15px;
}

.uploadCon {
    padding: 7px;
    border-radius: 8px;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    cursor: pointer;
}

.uploadCon>img {
    height: 1.5rem;
}

.passwordCon {
    padding: 4px;
    padding-left: 1rem;
    border-radius: 8px;
    height: 35px;
    width: 20rem;
    border: 1px solid rgba(230, 232, 236, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.passwordCon input {
    height: 92%;
    width: 80%;
    font-size: 13px;
    font-family: 'Manrope', sans-serif;
    border: none;
    background: none;
    outline: none;
}

.passwordCon input:-webkit-autofill {
    height: 92%;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.passwordCon svg {
    height: 15px;
    fill: #242436;
    cursor: pointer;
}

.confirmBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 5px 13px;
    height: 35px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.confirmBtn h5{
    color: white;
    font-size: 13px;
}

.preDomain {
    padding: 0px 12px;
    background: rgba(2, 98, 223, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.preDomain h5 {
    margin: 0;
    font-size: 13px;
    color: #565656;
}

.list{
    max-width: 16rem;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.listItem h5 {
    margin: 0;
    text-transform: capitalize;
}

.accrdionText {
    font-family: 'Manrope', sans-serif !important;
    color: #666563;
    font-size: 13px !important;
}
