.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: white;
    padding: 1.7rem 2rem 2rem 2rem;
    border-radius: 8px;
    width: 400px;
    max-height: 85vh;
    overflow-y: auto;
    text-align: left;
    border: none;
    position: relative;
}

.close {
    position: absolute;
    right: 23px;
    top: 16px;
    cursor: pointer;
}

.close img {
    height: 1rem;
    width: 1rem;
}
.close svg {
    height: 1.5rem;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.summaryBg h2 {
    margin: 10px 0;
}

.para {
    margin-bottom: 15px;
    font-size: 15px;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}