:root {
    --black: black;
    --blue: #0262DF;
}

.section {
    /* border: 1px solid black; */
    background: white;
    padding: 5rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    /* border: 1px solid black; */
    /* min-height: 50vh; */
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.card h5,
h4 {
    margin: 0;
}

.subheading {
    font-size: 32px;
    font-family: "Manrope", sans-serif;
    color: black;
    text-align: start;
    margin: 0;
    line-height: 2.3rem;
}

.text {
    font-family: "Manrope", sans-serif;
    text-align: start;
    color: #3F3F3F;
    font-size: 15px;
    font-weight: 500;
    margin: 1rem 0;
}

.boxMiniTitle {
    font-family: "Manrope", sans-serif;
    text-align: start;
    color: black;
    font-size: 14px;
    font-weight: 600;
    margin: 1rem 0;
}

.boxTitle {
    color: white;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-top: 1rem;
}

.boxesList {
    display: flex;
    justify-content: space-between;
    align-items: start;
    /* gap: 2rem; */
    margin-top: 3rem;
    gap: 2rem;
}

.boxBlue {
    background: linear-gradient(90deg, #0262DF 0%, #013579 100%);
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); */
    border-radius: 15px;
    gap: 0.5rem;
    position: relative;
    padding: 2rem 2rem;
    width: 22rem;
    transition: all 0.3s ease;
}

.boxBlue:hover{
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.boxBlue h5 {
    color: white !important;
}

.boxWhite {
    background: #FFFFFF;
    border: 1px solid #9090902e;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 16%);
    border-radius: 15px;
    gap: 0.5rem;
    position: relative;
    padding: 2rem 2rem;
    width: 22rem;
    transition: all 0.3s ease;
}

.boxWhite:hover{
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.boxBlue h5 {
    color: white !important;
}

.whiteBlue h5 {
    color: #3F3F3F !important;
}

.priceText {
    font-family: "Manrope", sans-serif;
    text-align: start;
    font-size: 30px;
    font-weight: 600;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
}

.boxText {
    font-family: "Manrope", sans-serif;
    text-align: start;
    color: white;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #ffffffe6;
}

.colored {
    color: #0262DF;
    font-size: 13px;
    font-weight: 600;
    text-align: start;
    margin: 0;
    margin-top: 0.5rem;
    cursor: pointer;
}

.colored:hover {
    text-decoration: underline;
}

.whiteBtn {
    outline: none;
    border: none;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 1rem;
    margin-top: 2rem;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: all 0.15s ease;
}

/* .whiteBtn:hover {
    transform: scale(1.03);
} */

.whiteBtn h4 {
    color: var(--blue);
    font-size: 14px;
    font-weight: 700;
}

.blueBtn {
    outline: none;
    border: none;
    background: linear-gradient(90deg, #0262DF 0%, #013579 100%);
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 1rem;
    margin-top: 2rem;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: all 0.15s ease;
}

/* .blueBtn:hover {
    transform: scale(1.03);
} */

.blueBtn h4 {
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.blue {
    color: #0262df;
}

.center {
    width: 100%;
    text-align: center;
    font-size: 13px !important;
}

.gradientBox {
    background-image: linear-gradient(to top, #0262df 0%, #04befe 100%) !important;
}

.gradientBtnBlue {
    background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important;
}

.gradientBtnWhite {
    background: linear-gradient(to top, #d4d4d4 0%, #efefef 100%) !important;
}

.gradientTxtBlack {
    background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, rgb(72 72 72 / 89%) 0.89%, rgb(56 56 56) 17.23%, rgb(0 0 0 / 65%) 42.04%, rgb(0 0 0 / 79%) 55.12%, #333333 71.54%, #404040 100%);
}

.flexContainer {
    display: flex;
    justify-content: center;
}

.flexItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heading {
    font-family: sans-serif, manrope;
    color: black;
    margin: 0;
    margin-bottom: 3rem;
}

.cardLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid black; */
}

.cardLeft h2 {
    color: white;
    margin: 0;
    font-weight: 600;
    margin-bottom: 20px;
}

.cardLeft button {
    background: #FFFFFF;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 13px;
}

.cardLeft button h5 {
    color: #0262DF;
    margin: 0;
    font-weight: 600;
}

.cardRight {
    /* border: 1px solid black; */
}

.cardRight h5 {
    color: white;
    margin: 0;
    font-weight: 400;
}

.list {
    margin-top: 25px;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
}

.listItem img {
    height: 15px;
}

.faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 5rem;
    /* margin-bottom: 15rem; */
    gap: 3rem;
}

.faqsCon {
    max-width: 40rem;
}

.AccordionHeader>h5 {
    font-size: 16px;
    font-weight: 700;
}

.priceText span{
    font-size: 18px;
    font-weight: 300;
}

.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.pricingBtns {
  max-width: 310px;
  width: fit-content;
  padding: 0.2rem;
  border-radius: 25px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

}

.saveText{
    color: #606060;
    font-size: 12px;
}

/* .pricingBtns button {
  background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important;
} */

.pricingBtns button h5{
    font-size: 14px;
    font-weight: 600;
}

.activeButton,
.inactiveButton {
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    width: 6.5rem;
    height: 2rem;
    border: 1px solid transparent;
    /* transition: background-color 0.3s, color 0.s; */
}

.activeButton {
    /* border: none; */
    /* background-color: #0262DF; */
    background: linear-gradient(90deg, #0262DF 0%, #013579 100%);
    color: white;
}

.inactiveButton {
  background: transparent;
  color: black;
}  

@media (max-width: 2000px) {
    .heading {
        font-size: 45px;
    }

    .cardLeft h2 {
        font-size: 28px;
    }

    .boxBlue{
        width: 22rem;
    }

    .boxWhite{
        width: 22rem;
    }

}

@media (max-width: 1550px) {
    .boxWhite {
        padding: 2rem 1.5rem;
    }

    .boxBlue {
        padding: 2rem 1.5rem;
    }

    .listItem{
        margin-bottom: 7px;
    }

    .list{
        margin-top: 12px;
    }

    .blueBtn{
        margin-bottom: 10px;
        padding: 9px 1rem;
    }

    .whiteBtn{
        margin-bottom: 10px;
        padding: 9px 1rem;
    }
    
}

@media (max-width: 1360px) {
    .box {
        padding: 2rem 1rem;
    }

    .subheading br {
        display: none;
    }

    .text br {
        display: none;
    }

    .boxText br {
        display: none;
    }
}

@media (max-width: 1200px) {
    .heading {
        font-size: 40px;
    }

    .cardLeft h2 {
        font-size: 27px;
    }

    .text {
        font-size: 15px;
    }

    .faqs {
        margin-bottom: 10rem;
    }
}

@media (max-width: 1150px) {
    .boxesList {
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 2rem;
    }
}

@media (max-width: 1000px) {
    .heading {
        font-size: 35px;
    }

    .cardLeft h2 {
        font-size: 25px;
    }

    .subheading {
        font-size: 28px;
    }

    .text {
        font-size: 13px;
    }

    .AccordionHeader>h5 {
        font-size: 14px;
    }

    .accordionText {
        font-size: 12px;
    }

    .card>div {
        padding: 1rem;
    }

    .howItWorks {
        padding: 1rem 3rem;
    }

}

@media (max-width: 800px) {
    .heading {
        font-size: 28px;
    }

    .cardLeft h2 {
        font-size: 22px;
    }

    .text {
        margin: 0;
    }

    .faqs {
        margin-bottom: 0;
    }

    .section{
        padding: 3rem;
    }
}

@media (max-width: 600px) {
    .card {
        flex-direction: column;
        padding: 0;
        gap: 1.3rem;
    }

    .cardLeft button {
        border-radius: 7px;
        padding: 8px;
    }

    .cardLeft button h5 {
        font-size: 13px;
    }

    .heading {
        font-size: 22px;
    }

    .cardLeft h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .text {
        font-size: 13px;
    }

    .text br {
        display: none;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 18px;
        text-align: center;
    }

    .text {
        font-size: 13px;
        text-align: center;
    }

    .text br {
        display: none;
    }

    .subheading {
        font-size: 22px;
    }

    .text {
        font-size: 12px;
    }

    .faqs{
        margin-top: 1rem;
    }
}

@media (max-width: 400px) {
    .card > div{
        padding: 1rem;
    }
    .boxBlue, .boxWhite{
        width: 18rem;
    }
}