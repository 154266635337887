.doc {
    border-radius: 15px;
}

.pdfPage {
    border-radius: 15px;
    overflow: hidden;
    border: 12px solid #fbfbfd;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #fbfbfd !important;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    /* min-height: 410px !important; */
    /* border-radius: 11px; */
}

.react-pdf__message{
    display: none;
}

.css-pazuet {
    min-width: 44px !important;
}

.css-1yfkmem{
    max-height: 120vh !important;
}

@media (max-width: 900px) {
    .react-pdf__Page__canvas {
        height: auto !important;
    }
}