.element {
  overflow-y: auto;
}

.element::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
}

.li-items545 li {
  margin-bottom: 0px !important;
}

/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07) !important;
} */

.rightShadow{
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07) !important;
}