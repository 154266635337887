.gradientBox {
    background-image: linear-gradient(to top, #0262df 0%, #04befe 100%);
}

.browseBtn {
    background: none !important;
    color: white !important;
    border-radius: 8px !important;
    padding: 0 !important;
    width: 13rem !important;
    height: 46px !important;
    font-size: 14px !important;
    font-family: 'Manrope', sans-serif !important;
    font-weight: 500 !important;
    border: 1px solid white !important;
    cursor: pointer !important;
    text-transform: none !important;
    border-radius: 10px !important;
    transition: transform 0.3s ease, background-color 0.2s ease !important;
}

.browseBtn:hover{
    transform: scale(1.04) !important;
    font-weight: 600 !important;
}

.pitchDeckBtn {
    background: white !important;
    /* background: linear-gradient(90deg, #0262DF 0%, #004DB1 100%) !important; */
    color: #0262DF !important;
    border-radius: 8px !important;
    padding: 0 !important;
    width: 13rem !important;
    height: 46px !important;
    font-size: 14px !important;
    font-family: 'Manrope', sans-serif !important;
    /* border-color: #0262DF !important; */
    cursor: pointer !important;
    text-transform: none !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    transition: transform 0.3s ease, background-color 0.2s ease !important;
}

.pitchDeckBtn:hover{
    transform: scale(1.04) !important;
    font-weight: 700 !important;
}

@media (max-width: 1200px) {
    .browseBtn {
        width: 13rem !important;
        height: 50px !important;
    }
    
    .pitchDeckBtn {
        width: 13rem !important;
        height: 50px !important;
    }
}

@media (max-width: 800px) {
    .howItWorksBtn {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 3rem !important;
        font-size: 12px !important;
    }

    .browseBtn {
        width: 12rem !important;
        height: 44px !important;
    }

    .pitchDeckBtn {
        width: 12rem !important;
        height: 44px !important;
    }
}

@media (max-width: 600px) {
    .howItWorksBtn {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 3rem !important;
        font-size: 12px !important;
    }

    .browseBtn {
        width: 10rem !important;
        height: 38px !important;
        font-size: 12px !important;
    }

    .pitchDeckBtn {
        width: 10rem !important;
        height: 38px !important;
        font-size: 12px !important;
    }

    .comingSoonBtn {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        margin-bottom: 1rem !important;
        font-size: 13px !important;
    }
}

@media (max-width: 480px) {
    .browseBtn {
        width: 60% !important;
        height: 33px !important;
    }

    .pitchDeckBtn {
        width: 60% !important;
        height: 33px !important;
    }

    .howItWorksBtn {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
        margin-top: 1rem !important;
        font-size: 11px !important;
    }
}