.section {
  /* border: 1px solid black; */
  background: white;
  padding: 7rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  /* border: 1px solid black; */
  /* min-height: 50vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.subheading {
  font-size: 35px;
  font-family: "Manrope", sans-serif;
  color: black;
  text-align: start;
  margin: 0;
  line-height: 2.3rem;
}

.text {
  font-family: "Manrope", sans-serif;
  text-align: start;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
}

.blue {
  color: #0262df;
}

.boxTitle {
  color: black;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

.boxesList {
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* gap: 2rem; */
  margin-top: 5rem;
  margin-bottom: 4rem;
}

.box {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding: 2rem;
  border-radius: 15px;
  transition: box-shadow 0.3s ease;
}

/* .box:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
} */

/* .box:hover .boxRightLine, .box:hover .boxLeftLine{
  border-bottom: 1px solid rgba(0, 0, 0, 0);
} */

.boxRightLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  width: 38%;
  position: absolute;
  top: 52px;
  right: 0;
  z-index: 0;
  transition: all 0.3s ease;
}

.boxLeftLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  width: 38%;
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 0;
  transition: all 0.3s ease;
}

.miniBox {
  height: 3rem;
  width: 3rem;
  background: linear-gradient(91.4deg, #0262df 1.19%, #013579 96.54%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.miniBox img {
  height: 1.5rem !important;
}

.boxText {
  font-family: "Manrope", sans-serif;
  text-align: start;
  color: #3f3f3f;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.colored {
  color: #0262df;
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  margin: 0;
  margin-top: 0.5rem;
  cursor: pointer;
}

.colored:hover {
  text-decoration: underline;
}

.startBtn {
  outline: none;
  border: none;
  background: none;
  background: linear-gradient(90deg, #0262df 0%, #013579 100%);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 12px 3rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
/* 
.startBtn:hover{
  transform: scale(1.03);
} */

.startBtn h5 {
  color: white;
  margin: 0;
  transition: all 0.3s ease;
}

/* .startBtn:hover h5{
  font-weight: 700;
} */

@media (max-width: 1550px) {
  .miniBox {
    height: 2.6rem;
    width: 2.6rem;
  }

  .miniBox img {
    height: 1.5rem;
  }

  .section {
    padding: 6rem 3rem;
  }
  .text br {
    display: none;
  }

  .boxText br {
    display: none;
  }
}

@media (max-width: 1360px) {
  .box {
    padding: 0 1rem;
  }

  .subheading br {
    display: none;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1100px) {
}

@media (max-width: 1000px) {
  .subheading {
    font-size: 28px;
  }

  .text {
    font-size: 13px;
  }

  .AccordionHeader > h5 {
    font-size: 14px;
  }

  .accordionText {
    font-size: 12px;
  }

  .card > div {
    padding: 1rem;
  }

  .subheading,
  .text {
    text-align: center;
  }

  .howItWorks {
    padding: 1rem 3rem;
  }

  .boxesList {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: 900px) {
}

@media (max-width: 800px) {
  .boxesList {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  .boxLeftLine,
  .boxRightLine {
    display: none;
  }

  .boxText {
    max-width: 20rem;
  }
}

@media (max-width: 600px) {
}

@media (max-width: 480px) {
  .subheading {
    font-size: 22px;
  }

  .text {
    font-size: 12px;
  }
}
