.myDiv {
    position: relative;
    background: #0362e0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    height: 100px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    padding-left: 1rem;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for box-shadow and transform */
}

.myDiv::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%; /* Start from the middle */
    width: 0; /* Start with no width */
    height: 3px; /* Adjust the thickness of your border here */
    background-color: #fff; /* Color of the border */
    transition: all 0.3s ease; /* Smooth transition for the border */
}

.myDiv:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Darker or larger shadow on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.myDiv:hover::after {
    width: 100%; /* Full width on hover */
    left: 0; /* Reset left position */
}


.gifContainer {
    position: relative;
    height: 0; /* This will be overridden by padding-bottom to maintain aspect ratio */
    padding-bottom: calc(423px / 1000px * 100%); /* Height / Width * Container Width */
    width: 100%;
}

.responsiveGif {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 500px; /* Fixed height, but can be adjusted if necessary */
    object-fit: cover; /* This will cover the area without losing aspect ratio */
    object-position: bottom; /* Aligns the image to the bottom */
}

@media (max-width: 900px) {
    .gifContainer {
        padding-bottom: 70%; /* Adjust the percentage based on the GIF's aspect ratio at smaller widths */
    }
    .responsiveGif {
        height: auto; /* Allows the GIF to adjust its height automatically */
    }
}

/* SectionB.css */

/* This will ensure the ellipse is centered and covers the width properly */
.ellipse-container {
    position: absolute;
    left: 50%;
    top: 200;
    transform: translateX(-50%);
    width: 100vw; /* Use 100vw to cover the full viewport width */
    height: auto; /* Keep the original aspect ratio */
    z-index: -1;
    overflow: hidden; /* Hide any overflow */
    pointer-events: none; /* Allows clicking on elements below */
  }
  
  /* Make sure the container of the ellipse has a relative position */
  /* so the absolute positioning of the ellipse is relative to this container */
  .section-container {
    position: relative;
    overflow: hidden; /* Hide any overflow from the ellipse */
  }
  
  /* Ensure the rest of the content in the Box has a higher z-index than the ellipse */
  .content-above-ellipse {
    position: relative;
    z-index: 1;
  }
  
  /* Rest of your CSS for myDiv, responsiveGif, etc. */
  
