html {
  height: 100%;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@media screen and (max-width: 768px) {
  .popup {
    justify-content: center;
    align-items: center;
  }
}

  
  @media only screen and (max-width: 580px) {
    .popup {
      width: 90%;
      max-width: 350px; /* Adjust this value as needed */
    }
  }
  
  .popup-inner {
    background-color:rgba(23, 42, 55, 1) ;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    width: 100%;
    margin: 20px;
    position: relative;
  }
  
  .popup-inner h2 {
    font-size: 24px;
    margin-top: 0;
  }
  
  .popup-inner form {
    margin-top: 20px;
  }
  
  .popup-inner .form-group {
    margin-bottom: 10px;
  }
  
  .popup-inner label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .popup-inner input[type="text"],
  .popup-inner input[type="password"],

  .popup-inner input[type="email"] {
    padding: 5px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .popup-inner button[type="submit"] {
    display: block;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .close-btn {
    position: absolute;
    top: -10px;
    right: 5px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
   .input-box1::placeholder {
    font-weight: 100;
    color: #999999;
    font-size: 17px;
  }
  
    .input-box1 {
    height: 64px;
    border: 1.5px solid rgba(23, 42, 55, 1);
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    color: #333;
    outline: none;
    transition: height 0.2s ease-in-out;
    box-sizing: border-box;
    overflow-y: auto;
    border-radius: 5px;
  }
  
  
  @media (max-width: 768px) {
    .input-box1::placeholder {
      font-size: 25px;
    }
  }


  .Check-point {
    font-size: 15px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    .input-box1::placeholder {
      font-size: 12px;
    }
  }


  .label2 {
    font-size: 14px;
    font-weight: 600;
    align-self: center;
    margin: 0 auto;
    text-align: center;
  }
  

  @media (max-width: 768px) {
    .label2 {
        margin-left: 3.5rem;
        width: 60%;
        
    }
  }

  .weekly-label{
    font-size: 1px;
  }



   /* input[type="checkbox"]::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #87C7F1;
    border-radius: 2px;
    visibility: hidden;
  }
  input[type="checkbox"]:checked::before {
    visibility: visible;
  } */
